define("discourse/plugins/discourse-doc-categories/discourse/components/doc-category-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/models/topic", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "select-kit/components/topic-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _topic, _dIcon, _i18n, _topicChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DocCategorySettings extends _component.default {
    static shouldRender(args, context) {
      return context.siteSettings.doc_categories_enabled;
    }
    static #_ = (() => dt7948.g(this.prototype, "indexTopicId", [_tracking.tracked], function () {
      return this.args.outletArgs.category.custom_fields.doc_category_index_topic;
    }))();
    #indexTopicId = (() => (dt7948.i(this, "indexTopicId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "indexTopic", [_tracking.tracked]))();
    #indexTopic = (() => (dt7948.i(this, "indexTopic"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "loadingIndexTopic", [_tracking.tracked], function () {
      return !!this.indexTopicId;
    }))();
    #loadingIndexTopic = (() => (dt7948.i(this, "loadingIndexTopic"), void 0))();
    constructor() {
      super(...arguments);
      if (this.indexTopicId) {
        this.loadIndexTopic();
      }
    }
    get category() {
      return this.args.outletArgs.category;
    }
    get errorMessage() {
      if (this.loadingIndexTopic) {
        return;
      }
      if (this.indexTopicId && !this.indexTopic) {
        return (0, _i18n.default)("doc_categories.category_settings.index_topic.errors.topic_not_found");
      } else if (this.indexTopic && this.indexTopic.category_id !== this.category.id) {
        return (0, _i18n.default)("doc_categories.category_settings.index_topic.errors.mismatched-category", {
          category_name: this.indexTopic.category?.name
        });
      }
    }
    get indexTopicContent() {
      if (this.loadingIndexTopic || !this.indexTopicId) {
        return [];
      }
      return [this.indexTopic];
    }
    get searchFilters() {
      return ["in:title", "include:unlisted", `category:${this.category.id}`].join(" ");
    }
    get shouldDisplayErrorMessage() {
      return !this.loadingIndexTopic && this.errorMessage;
    }
    async loadIndexTopic() {
      this.loadingIndexTopic = true;
      try {
        // using store.find doesn't work for topics
        const topic = await _topic.default.find(this.indexTopicId, {});
        this.onChangeIndexTopic(this.indexTopicId, topic);
      } finally {
        this.loadingIndexTopic = false;
      }
    }
    onChangeIndexTopic(topicId, topic) {
      this.indexTopic = topic;
      this.indexTopicId = topicId;
      this.category.custom_fields.doc_category_index_topic = topicId;
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChangeIndexTopic", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <h3>{{i18n "doc_categories.category_settings.title"}}</h3>
        <section
          class="field doc-categories-settings doc-categories-settings__index-topic"
        >
          <label class="label">
            {{i18n "doc_categories.category_settings.index_topic.label"}}
          </label>
          <div class="controls">
            <TopicChooser
              @value={{this.indexTopicId}}
              @content={{this.indexTopicContent}}
              @onChange={{this.onChangeIndexTopic}}
              @options={{hash additionalFilters=this.searchFilters}}
            />
            {{#if this.shouldDisplayErrorMessage}}
              <div class="validation-error">
                {{dIcon "xmark"}}
                {{this.errorMessage}}
              </div>
            {{/if}}
          </div>
        </section>
      
    */
    {
      "id": "/M2tOh3E",
      "block": "[[[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"doc_categories.category_settings.title\"],null]],[13],[1,\"\\n    \"],[10,\"section\"],[14,0,\"field doc-categories-settings doc-categories-settings__index-topic\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"doc_categories.category_settings.index_topic.label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"indexTopicId\"]],[30,0,[\"indexTopicContent\"]],[30,0,[\"onChangeIndexTopic\"]],[28,[32,2],null,[[\"additionalFilters\"],[[30,0,[\"searchFilters\"]]]]]]],null],[1,\"\\n\"],[41,[30,0,[\"shouldDisplayErrorMessage\"]],[[[1,\"          \"],[10,0],[14,0,\"validation-error\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[\"xmark\"],null]],[1,\"\\n            \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-doc-categories/discourse/components/doc-category-settings.js",
      "scope": () => [_i18n.default, _topicChooser.default, _helper.hash, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DocCategorySettings;
});